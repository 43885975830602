<template>
  <el-dialog
    title="分类"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="名称" prop="kName">
        <el-input v-model="temp.kName"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
    },
    row_type(val) {
      Object.assign(this.temp, val);
    },
  },
  data() {
    return {
      dialogVisible: false,
      temp: {
        id: "",
        kName: "",
      },
      rules: {
        kName: [
          {
            required: true,
            message: "名称必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.article_types_kinds_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
